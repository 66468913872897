<template>
  <div class="buttons-container">
    <div class="button" v-for="(link, i) in links" v-bind:key="link.url">
      <a class="link-button" v-if="link.url" target="_blank" :href="link.url" v-html="link.label" />
      <router-link v-if="link.localUrl" :to="link.localUrl" v-html="link.label" />
      <div v-if="link.list">
          <div class="link-list-button" v-on:click="toggleOpenListLink(i)" :class="{ 'is-open': link.open, 'is-closed': !link.open }">
              <div class="link-list-button-label" v-html="link.label" />
              <div v-if="link.open" class="open-close-button carat-up" />
              <div v-if="!link.open" class="open-close-button carat-down" />
          </div>
          <div v-if="link.open">
              <a v-for="subLink in link.list" v-bind:key="subLink.url" class="link-button inner-link" target="_blank" :href="subLink.url" v-html="subLink.label">
              </a>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

const list = [
  /*
  {
    label: `<div>Super OK Live at Molly Malone's</div><div>December 15th - 9pm</div>`,
    url: 'https://www.eventbrite.com/e/super-ok-at-molly-malones-tickets-773989763217'
  },
  */
  {
    label: 'Super OK "Push" Official Music Video',
    url: 'https://youtu.be/tGf-F-sF7BQ?si=MVKpmm4viQ5zGRUE'
  },
  {
    label: `Is This It - The Strokes`,
    url: 'https://youtu.be/-o6GIZLMMFk'
  },
  {
    label: `<div>Fluorescent Adolescent - Arctic Monkeys</div><div><i>acoustic cover</i></div>`,
    url: 'https://youtu.be/ZLGS8FeqOiw'
  },
  {
    label: 'Not Ready Yet - Acoustic Performance',
    url: 'https://youtu.be/Gw9MSM_15Po'
  },
  {
    label: 'Astrofold',
    open: false,
    list: [
      {
        label: 'Spotify',
        url: 'https://open.spotify.com/album/3pc9hYlNq0kUwDfeoCgRDY?si=BXQKzKSbQgisJeFd5RyZRg'
      },
      {
        label: 'YouTube',
        url: 'https://www.youtube.com/playlist?list=OLAK5uy_l5HGB-87UkabHceKO7KNpFww0kBPPPvac'
      },
      {
        label: 'Apple Music',
        url: 'https://music.apple.com/us/album/colour-gravitas/1671597922'
      },
      {
        label: 'Amazon Music',
        url: 'https://www.amazon.com/dp/B0BVMZJ6NK'
      },
      {
        label: 'Tidal',
        url: 'https://listen.tidal.com/album/276486299'
      },
      {
        label: 'SoundCloud',
        url: 'https://soundcloud.com/astrofold/sets/colour-and-gravitas'
      },
      {
        label: 'Bandcamp',
        url: 'https://astrofold.bandcamp.com/album/colour-gravitas'
      },
      {
        label: 'Instagram',
        url: 'https://www.instagram.com/astrofoldmusic/'
      },
      {
        label: 'Facebook',
        url: 'https://www.facebook.com/astrofoldmusic'
      },
    ]
  },
  {
    label: 'Super OK',
    open: false,
    list: [
      {
        label: 'Spotify',
        url: 'https://open.spotify.com/album/75ijGKYMwGG5CZne26bz9q?si=4SEd8iK6TzeXaEYhR_FBvg'
      },
      {
        label: 'YouTube',
        url: 'https://www.youtube.com/@wearesuperok'
      },
      {
        label: 'Apple Music',
        url: 'https://music.apple.com/us/album/departures/1601663098'
      },
      {
        label: 'Amazon Music',
        url: 'https://www.amazon.com/dp/B09P1T6MNR/'
      },
      {
        label: 'Bandcamp',
        url: 'https://superok.bandcamp.com/album/departures'
      },
      {
        label: 'SoundCloud',
        url: 'https://soundcloud.com/wearesuperok/sets/departures'
      },
      {
        label: 'Instagram',
        url: 'https://www.instagram.com/wearesuperok/'
      },
      {
        label: 'Facebook',
        url: 'https://www.facebook.com/wearesuperok'
      },
      {
        label: 'Super OK Website',
        url: 'https://superok.band'
      }
    ],
  },
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/dvy.wav/'
  },
  {
    label: 'YouTube',
    url: 'https://www.youtube.com/@dvy_plays'
  },
  {
    label: 'TikTok',
    url: 'https://www.tiktok.com/@dvy.wav'
  },
];

export default {
  name: 'HiComponent',
  data: () => ({
    links: list
  }),
  methods: {
    toggleOpenListLink(index) {
      this.links[index].open = !this.links[index].open;
    }
  },
  components: {},
}

</script>

<style lang="scss">

.buttons-container {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  width: 85%;
  background: white;
  border: 1px solid gray;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;
  color: initial;
  text-decoration: unset;
    a {
        &:link, &:visited, &:hover, &:active {
            text-decoration: none;
            color: #2c3e50;
        }
    }
}

.link-button {
    display: block;
    padding: 15px;
    &:hover {
        -webkit-box-shadow: 1px 0 6px 4px rgb(150 150 150 / 60%);
        box-shadow: 1px 0 6px 4px rgb(150 150 150 / 60%);
    }
    font-weight: 500;
    user-select: none;
}

.link-list-button {
    display: block;
    padding: 15px;
    cursor: pointer;
    position: relative;
    &.is-open {
        border-bottom: 1px solid rgb(150 150 150 / 60%);
    }
    &.is-closed {
        &:hover {
            -webkit-box-shadow: 1px 0 6px 4px rgb(150 150 150 / 60%);
            box-shadow: 1px 0 6px 4px rgb(150 150 150 / 60%);
        }
    }

    .link-list-button-label {
        //margin-right: 40px;
    }

    color: #2c3e50;
    font-weight: 500;
    user-select: none;
}

.inner-link {
    border-bottom: 1px solid rgb(150 150 150 / 60%);
    background-color: rgb(150 150 150 / 15%);
    user-select: none;

    .link-button {
        color: black;
    }
}

.open-close-button {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
}
.carat-down {
    background-image: url('../assets/icons/iconmonstr-angel-down-thin-48.png');
}
.carat-up {
    background-image: url('../assets/icons/iconmonstr-angel-up-thin-48.png');
}

</style>
